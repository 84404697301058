@font-face {
  font-family: Neue;
  font-display: swap;
  src: url('/static/fonts/NeueHaasGroteskTextProVN-75Bold.otf');
}

.vnrTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  margin-bottom: 15px;
}
.title {
  font-family: Neue;
  font-size: 24px;
  line-height: 1.4;
  color: #FF8104;
  margin-bottom: 0;
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
}
.forexTitle {
  font-family: Neue;
  font-size: 22px;
  margin-bottom: 3px;
  line-height: 1.4;
  color: #FF8104;
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
}
.readMore {
  font-family: Nunito-Regular, Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.63;
  text-align: right;
  color: #333333;
  &:hover {
    color: #0872ca;
    font-family: Neue;
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
}
