@font-face {
  font-family: Neue;
  font-display: swap;
  src: url('/static/fonts/NeueHaasGroteskTextProVN-75Bold.otf');
}

.rowSliderWrapper {
  background-color: #fff;
  z-index: 3;
}
.header {
  margin: 20px auto;
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.homepageH1 {
  display: none;
}

.container {
  width: 100%;
  margin: 0 auto;
}
.paddingContent {
  width: 100%;
  max-width: 1336px;
  margin: 0 auto;
  padding: 48px 0;
  @media screen and (max-width: 768px) {
    padding: 12px;
  }
}
.footerContent {
  border-top: 1px solid #D8E1F5;
}
.homeTopSlider {
  padding-right: 20px;
  @media screen and (max-width: 768px) {
    padding-right: 0;
  }
}
.minorBanners {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 448px;
  width: 100%;

  @media screen and (max-width: 768px) {
    height: unset;
    flex-direction: row;
    gap: 8px;
    a {
      width: 100%;
      max-width: 424px !important;
      margin: 0 auto;
      // padding-bottom: 10px;
      div {
        border-radius: 8px;
      }
    }
  }
}
.brokerList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-image: url('/images/BG.svg');
  padding: 80px calc(50vw - 668px);
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 32px 12px;
    gap: 24px;
  }
}
.colProfileInformation {
  :global .ant-skeleton {
    min-height: 212px;
  }
  @media screen and (max-width: 576px) {
    min-height: 440px;
  }
}
.topBrokerPost {
  padding: 20px;
  @media screen and (max-width: 576px) {
    padding: 0;
  }
}
.colSliderBroker {
  min-height: 86px;
}
.backcomWrapper {
  padding: 32px 28px;
  background-color: #ff8104;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 268px;
  border-radius: 12px;
  @media screen and (max-width: 768px) {
    order: 2;
    max-width: unset;
    padding: 20px 18px;
    border-radius: 8px;
    gap: 20px;
  }
}
.backcom {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.backcomTitle {
  font-family: Rotulo;
  font-size: 32px;
  line-height: 1.33;
  color: #fff;
  @media screen and (max-width: 768px) {
    font-size: 23px;
  }
}
.backcomKey {
  font-family: Neue;
  font-size: 14px;
  line-height: 20px;
  padding-left: 12px;
  color: #FFF;
  border-left: 2px solid rgba(255, 255, 255, 0.40);
}
.registerButton {
  width: 100%;
  display: flex;
  height: 44px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #FFF;
  font-family: Neue;
  font-size: 14px;
  line-height: 20px;
  color: #0872CA;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.20);
  cursor: pointer;
}
.telegramDirectional {
  max-width: 848px;
  width: 100%;
  margin: 0 auto;
  
  border-radius: 8px;
border: 1px dashed #CDCDCD;
  :global .ant-row {
    @media screen and (max-width: 768px) {
      align-items: center;
      justify-content: center;
    }
  }
  > a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 25px 40px;
  }
  @media screen and (max-width: 768px) {
    
    width: calc(100% - 20px);
    > a {
      padding: 18px 12px;
    }
  }
}
.telegramDesc {
  font-size: 16px;
  font-family: Neue;
line-height: 24px;
color: #333;
@media screen and (max-width: 768px) {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding-bottom: 10px;
}
}
.telegramButton {
  display: flex;
padding: 9px 12px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
background-color: #26A4E3;

color: #FFF !important;
font-family: Neue;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px;
max-width: 168px;
margin-top: 16px;
svg {
  width: 28px;
  height: 28px;
}
@media screen and (max-width: 768px) {
  margin-top: 0;
}
}
.postGroups {
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding-top: 80px;
  > div {
    flex: 1;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.suMenhWrapper {
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-top: 80px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.suMenhImage {
  width: 400px;
  flex: none;
  > div {
    border-radius: 12px;
    box-shadow: 0px 4px 0px 0px rgba(8, 114, 202, 0.20);
  }
  @media screen and (max-width: 768px) {
    margin: 0 auto;
    width: 100%;
  }
}
.suMenh {
  padding: 32px;
  border-radius: 12px;
border: 1px solid rgba(2, 114, 202, 0.20);
background-color: #FFF;
box-shadow: 0px 4px 0px 0px rgba(8, 114, 202, 0.20);
@media screen and (max-width: 768px) {
  padding: 20px;
}
}
.vnrebates {
  color: #0872CA;
font-family: Neue;
font-size: 18px;
line-height: 28px; /* 155.556% */
letter-spacing: 4.5px;
text-transform: uppercase;
padding-bottom: 8px;
}
.vnrebatesBorderBottom {
  width: 80px;
height: 4px;
border-radius: 111px;
background-color: rgba(2, 114, 202, 0.10);
}
.suMenhContent {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #2E2E2E;
  font-family: Neue-Regular;
  font-size: 14px;
  line-height: 20px; /* 155.556% */
  highlight {
    color: #ff8104;
  }
}
.serviceForm {
  border-radius: 12px;
  background-color: #0872CA;
  padding: 24px;
  display: flex;
  flex-direction: row;
  gap: 32px;
  width: 100%;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    border-radius: 8px;
  }
}
.serviceList {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    gap: 8px;
  }
}
.serviceRegister {
  color: #FFF;
  font-family: Neue-Regular;
  font-size: 14px;
  line-height: 20px; /* 142.857% */
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.serviceWrapper {
  display: inline-flex;
  gap: 8px;
  align-items: center;
}
.service {
  color: #FFF;
font-family: Neue;
font-size: 14px;
line-height: 20px; /* 142.857% */
}
.order{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #FFF;
  color: #2E2E2E;
  display: grid;
  span {
    place-self: center;
    font-family: Neue;
  }
}
.registerWrapper {
  display: inline-flex;
  gap: 5px;
  align-items: center;
  flex-grow: 1;
}
.buttonLogin {
  width: 120px;
  height: 44px;
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 15px;
  border-radius: 8px;
  background-color: #FFF;
  color: #0872CA;
  box-shadow: 0px 4px 0px 0px rgba(255, 255, 255, 0.20);
  cursor: pointer;
  font-family: Neue;
  font-size: 14px;
  line-height: 20px;
  &:hover {
    background-color: #ee7938;
    .loginImage,
    .textLogin {
      color: #ffffff;
    }
  }
}
.inputForm {
  width: 100%;
  height: 44px;
  border-radius: 8px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #FFF !important;
  border: solid 1px rgba(255, 255, 255, 0.20) !important;
  background-color: #0872CA !important;
  box-shadow: 0px 4px 0px 0px rgba(255, 255, 255, 0.20);
  &:hover {
    border: solid 1px #0872ca !important;
  }
  &:focus {
    border: solid 1px #0872ca !important;
  }
  &::placeholder {
    color: #FFF !important;
  }
}
.countdownSection {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4px);
  width: 424px;
  margin: 0 auto;
  text-align: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  @media screen and (max-width: 768px) {
    width: 20px;
    height: 100%;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: unset;
    left: unset;
    right: 0;
    padding-top: 2px;
  }
}